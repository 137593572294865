<template>
  <hs-form class="d-flex flex-column align-items-center w-100" @submit="updatePhone">
    <div class="mb-2" :class="alignSelfClass">
      <div v-if="isEditingPhone" class="d-flex flex-column">
        <div class="d-flex">
          <VueTelInput
            id="signUp_phone"
            v-model="form.phone"
            type="tel"
            :inputOptions="vueTelInputOptions"
            :defaultCountry="parseDDI"
            :preferredCountries="['BR', 'US', 'AO', 'PT', 'AR']"
            :disabledFetchingCountry="true"
            @blur="$v.form.phone.$touch()"
            @country-changed="countryChanged"
            @input="validateNumber"
          />
          <MButton class="mx-2 phone-class" variant="critical-outline" size="md" icon="times" @click.stop="cancel()" />
          <MButton
            class="phone-class"
            variant="success"
            size="md"
            icon="check"
            @click.stop="save()"
            :disabled="$v.form.phone.$error"
          />
        </div>
        <hs-form-invalid-feedback :state="!$v.form.phone.$error && isValidPhone">
          <span v-if="!$v.form.phone.required">
            {{ $t(`sign_up.sparkmembers.form.validation.required.phone`) }}
          </span>
          <span v-if="!isValidPhone && $v.form.phone.required">
            {{ feedbackInvalid }}
          </span>
        </hs-form-invalid-feedback>
      </div>
      <div v-else class="d-flex">
        <VueTelInput
          id="signUp_phone"
          v-model="form.phone"
          type="tel"
          :disabled="!isEditingPhone"
          :inputOptions="vueTelInputOptions"
          :defaultCountry="parseDDI"
          :preferredCountries="['BR', 'US', 'AO', 'PT', 'AR']"
          :disabledFetchingCountry="true"
          @blur="$v.form.phone.$touch()"
          @input="validateNumber"
        />
        <div v-b-tooltip.hover.top="buttonTooltipData()">
          <MButton
            size="sm"
            class="edit-phone"
            icon="pencil"
            @click.prevent="initEditing()"
            :disabled="resendDisabled"
          />
        </div>
      </div>
    </div>

    <div :class="[alignSelfClass, { is_invalid: invalidCode }, widthClass]">
      <hs-input
        v-if="isTouch"
        id="mobileCodeInput"
        maxlength="6"
        type="text"
        @blur="$v.form.code.$touch()"
        v-model="form.code"
      />
      <vue-auth-code-input
        v-else
        :class="{ is_invalid: invalidCode }"
        codeLenght="6"
        @inputChange="getCode"
        @inputComplete="removeWarning"
      />

      <hs-form-invalid-feedback :state="!invalidCode">
        <span v-if="invalidCode" class="font-size-xs">
          <hs-icon variant="regular" icon="exclamation-circle" size="12" />
          {{ $t(`sign_up.sparkmembers.form.fields.sms_code`) }}
        </span>
      </hs-form-invalid-feedback>
      <span class="font-size-xs">
        Reenviar o código em <strong>{{ countDownDisplay }}</strong> segundos.
      </span>
    </div>
    <MButton
      class="my-2 ml-2"
      :class="alignBtnSelfClass"
      variant="link"
      size="md"
      @click="resendCode(false)"
      :disabled="resendDisabled || isEditingPhone"
    >
      <hs-icon class="mr-2" variant="regular" icon="redo-alt" size="13" />
      <span>{{ 'Reenviar código' }}</span>
    </MButton>
    <hs-button
      data-testid="validate-phone"
      size="lg"
      class="btn-validation mb-4"
      variant="purple"
      block
      type="submit"
      :disabled="$v.form.$invalid || isEditingPhone || !isValidPhone"
      >{{ $t(`sign_up.sparkmembers.form_validation.buttons.validate`) }}</hs-button
    >
  </hs-form>
</template>

<script>
import { hsForm } from '@/components';
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import VueAuthCodeInput from 'vue-auth-code-input';
import MButton from '@/shared/components/MButton.vue';
import { required, minLength } from 'vuelidate/lib/validators';
import { mask } from 'vue-the-mask';
import ToasterHelper from '@/shared/helpers/toast';
import { phoneValidationService } from '@/services';
import BrowserHelper from '@/shared/helpers/browser';

export default {
  directives: { mask },
  props: {
    phone: {
      required: true,
      type: String,
    },
    userId: {
      required: true,
      type: String,
    },
    isSignUp: {
      type: Boolean,
    },
    invalidCode: {
      type: Boolean,
    },
    ddi: {
      required: true,
      type: String,
    },
  },
  components: {
    MButton,
    VueAuthCodeInput,
    VueTelInput,
    hsForm,
  },
  validations: {
    form: {
      phone: {
        required,
      },
      code: {
        required,
        minLength: minLength(6),
      },
    },
  },
  data() {
    return {
      form: {
        phone: '',
        code: '',
      },
      vueTelInputOptions: {
        placeholder: this.$t(`sign_up.sparkmembers.form.fields.placeholder_phone`),
      },
      smsTokenId: null,
      isEditingPhone: false,
      countDown: 60,
      countDownDisplay: '',
      resendDisabled: true,
      countryDDI: '',
      isValidPhone: true,
    };
  },
  computed: {
    alignSelfClass() {
      return this.isSignUp ? 'align-self-start' : '';
    },
    alignBtnSelfClass() {
      return this.isSignUp ? 'align-self-md-start' : '';
    },
    parseDDI() {
      return +this.countryDDI;
    },
    widthClass() {
      return this.isSignUp ? 'w-100' : '';
    },
    isTouch() {
      return BrowserHelper.isMobile() || BrowserHelper.isTablet();
    },
    feedbackInvalid() {
      return this.ddi === '55'
        ? this.$t(`sign_up.sparkmembers.form.fields.phone_text`)
        : this.$t(`sign_up.sparkmembers.form.fields.phone_text_international`);
    },
  },
  methods: {
    buttonTooltipData() {
      return {
        title: this.$t('components.phone-code-auth.tooltip.title'),
        disabled: !this.resendDisabled,
      };
    },
    validateNumber(phoneNumber, phoneObject) {
      this.isValidPhone = phoneObject.valid;
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownDisplay = this.countDown.toLocaleString('pt-BR', { minimumIntegerDigits: 2 });
          this.countDownTimer();
        }, 1000);
      } else {
        this.resendDisabled = false;
      }
    },
    getCode(code) {
      this.form.code = code.join('');
    },
    async resendCode(isPhoneChanging = false) {
      try {
        if (!this.isEditingPhone) {
          this.resendDisabled = true;
          await this.sendSMS();
          this.countDownTimer();
          this.$emit('resendedCode', { isPhoneChanging });
        }
      } catch (e) {
        ToasterHelper.dangerMessage(this.$t('components.phone-code-auth.toast.resend-error'));
      }
    },
    async sendSMS() {
      try {
        const {
          data: { sms_token_id, expiration_time },
          status,
        } = await phoneValidationService.sendPhoneValidationToken(
          this.userId,
          this.countryDDI,
          this.form.phone,
          this.smsTokenId,
          this.isSignUp
        );
        this.smsTokenId = sms_token_id;
        this.countDown = expiration_time;

        if (status == 204) {
          this.updatePhone();
        } else {
          this.$emit('loadFinished');
          ToasterHelper.successMessage(this.$t('components.phone-code-auth.toast.success'));
        }
      } catch (e) {
        ToasterHelper.dangerMessage(this.$t('components.phone-code-auth.toast.success'));
      }
    },
    initEditing() {
      this.isEditingPhone = true;
    },
    cancel() {
      this.isEditingPhone = false;
    },
    save() {
      if (this.form.phone.length) {
        this.isEditingPhone = false;
        this.$emit('changePhoneNumber', { phone: this.form.phone, ddi: this.countryDDI });
        this.resendCode();
      }
    },
    countryChanged(country) {
      this.countryDDI = country.dialCode;
    },
    removeWarning() {
      this.$emit('removeWarning');
    },
    updatePhone() {
      const phone = this.form.phone.replace(/[\s\-/{()}]+/g, '');
      this.$emit('updatePhone', { smsToken: this.form.code, smsTokenId: this.smsTokenId, phone, ddi: this.countryDDI });
      this.form.code = '';
    },
  },
  async created() {
    this.form.phone = this.phone;
    this.countryDDI = this.ddi;
    if (this.ddi == 55) {
      await this.sendSMS();
      this.countDownTimer();
    } else {
      this.updatePhone();
    }
  },
};
</script>

<style lang="scss" scoped>
/deep/ .edit-phone {
  color: $purple-dark;
  &:disabled {
    background-color: transparent;
    border-color: transparent;
    color: #6c6c6c;
  }
}

/deep/ .form-control {
  border: 1px solid $grey-ba;
  border-radius: 4px;
  margin-bottom: 5px;
  &:focus {
    border-color: $purple-dark;
  }

  &:focus-visible {
    outline: auto $purple-dark !important;
  }
}

.is_invalid {
  /deep/ .form-control {
    border: 1px solid #db3939;
  }
}

.phone-class {
  min-width: 39px;
}
.btn-validation {
  border-radius: 4px;
  &.disabled {
    color: $color-neutral-600;
    background-color: #cfcfcf;
  }
}
/deep/ .auth-code_container {
  justify-content: start !important;
  gap: 6px;
  .auth-code_input-box {
    margin: 0 !important;
    .auth-code_input {
      font-size: 0.75rem !important;
      color: $color-neutral-600;
      border: 1px solid $grey-ba;
      &:focus {
        border-color: $purple-dark;
      }

      &:focus-visible {
        outline: auto $purple-dark !important;
      }
    }
  }

  &.is_invalid {
    .auth-code_input-box .auth-code_input {
      border: 1px solid #db3939;
    }
  }
}

/deep/ .vue-tel-input {
  max-width: 212px;
  &.disabled {
    border: none;
    font-weight: bold;
    pointer-events: none;
    max-width: 155px;
    .vti__input {
      font-weight: bold;
      padding-left: 0;
      background: inherit;
    }
    .vti__dropdown {
      padding-left: 0;
      .vti__dropdown-arrow {
        display: none;
      }
    }
  }
}
</style>
