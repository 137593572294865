<template>
  <b-modal
    size="md"
    centered
    hideFooter
    lazy
    body-class="pb-4 pt-2 px-4"
    header-class="pb-0 pt-2"
    :id="modalId"
    :cancel-title="$t('sparkmembers.members-list.desactive-enrollment-modal.buttons.cancel')"
    v-on="$listeners"
    @cancel="closeModal"
  >
    <hs-loading v-show="!isActive" />
    <div v-show="isActive">
      <header class="d-flex flex-column align-items-center text-align-center text-center">
        <img class="mb-4" width="200" :src="headerIllustration" alt="auth-code" />
        <h3 class="font-weight-bold mb-1 mb-4">
          {{ $t('my-account.views.account-settings.components.validation-modal.header.title') }}
        </h3>
        <p v-html="$t('my-account.views.account-settings.components.validation-modal.header.subtitle')"></p>
      </header>
      <PhoneCodeAuth
        :phone="phone"
        :userId="userId"
        :ddi="ddi"
        :is-sign-up="false"
        :invalidCode="invalidCode"
        v-on="$listeners"
        @loadFinished="loadFinished"
      />
      <div class="d-flex flex-column text-center">
        <hs-icon class="mb-2" variant="light" icon="exclamation-circle" size="17" />
        <span v-html="$t('my-account.views.account-settings.components.validation-modal.info')"></span>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { hsLoading } from '@/components';
import PhoneCodeAuth from '@/components/PhoneCodeAuth';

export default {
  components: {
    hsLoading,
    PhoneCodeAuth,
  },
  props: {
    modalId: {
      required: true,
      type: String,
    },
    phone: {
      required: true,
      type: String,
    },
    userId: {
      required: true,
      type: String,
    },
    ddi: {
      type: String,
    },
    invalidCode: {
      type: Boolean,
    },
  },
  data() {
    return {
      isActive: false,
      headerIllustration: require(`@/assets/images/myspark/robot-lock.svg`),
    };
  },
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'phone-validation-modal') {
        this.isActive = false;
      }
    });
  },
  methods: {
    closeModal() {
      this.isActive = false;
    },
    loadFinished() {
      this.isActive = true;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .modal {
  .modal-header {
    border: none;
  }
  .modal-dialog {
    max-width: 544px;
  }

  .modal-body {
    header h3 {
      font-size: 1.5rem;
    }
  }
}
</style>
